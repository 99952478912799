"use client";
import React, { useEffect } from "react";
import "@/custom.css";
import clsx from "clsx";
import { gtag } from "./GoogleTagManager";

export default function CookieBanner() {
  const [hidden, setHidden] = React.useState(true);

  useEffect(() => {
    try {
      const isCookieConsent = localStorage.getItem("cookie-consent");
      setHidden(!!isCookieConsent);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: boolean,
  ) => {
    try {
      e.preventDefault();
      // update google tag manager consent and save preferences in localStorage
      const consent: {
        analytics_storage: "granted" | "denied";
        ad_storage: "granted" | "denied";
        ad_personalization: "granted" | "denied";
        ad_user_data: "granted" | "denied";
      } = {
        analytics_storage: value ? "granted" : "denied",
        ad_storage: value ? "granted" : "denied",
        ad_personalization: value ? "granted" : "denied",
        ad_user_data: value ? "granted" : "denied",
      };
      gtag("consent", "update", consent);
      localStorage.setItem("cookie-consent", JSON.stringify(consent));
      setHidden(true);
    } catch (err) {
      console.error(err);
    }
  };

  const style = clsx(
    `cookie-consent-banner`,
    hidden && "cookie-consent-banner--hidden",
  );

  return (
    <div className={style}>
      <div className="cookie-consent-banner__inner">
        <div className="cookie-consent-banner__copy">
          <div className="cookie-consent-banner__header">
            THIS WEBSITE USES COOKIES
          </div>
          <div className="cookie-consent-banner__description">
            We use cookies to analyse traffic on our site. By clicking OK you
            are consenting that we get access to relevant data in order to
            improve the experience of using our website.
          </div>
        </div>

        <div className="cookie-consent-banner__actions gap-2">
          <div
            onClick={(e) => handleClick(e, true)}
            className="hover:cursor-pointer hover:bg-blue-700 flex w-24 bg-blue-600 justify-center items-center p-2 rounded-lg text-white"
          >
            OK
          </div>

          <div
            onClick={(e) => handleClick(e, false)}
            className="hover:cursor-pointer hover:bg-slate-100 flex w-24 bg-white justify-center items-center p-2 rounded-lg border-black ring-1 ring-black"
          >
            Decline
          </div>
        </div>
      </div>
    </div>
  );
}
