"use client";

import { useEffect, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useProModal } from "@/hooks/useProModal";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { Badge, Card, buttonVariants } from "./ui";
import { GoldPlanViewOnly } from "@/app/(dashboard)/pricing/components/GoldPlanCard";
import { TooltipProvider } from "./ui/tooltip";
import { PlatinumPlanViewOnly } from "@/app/(dashboard)/pricing/components/PlatinumPriceCard";
import { DiamondPlanViewOnly } from "@/app/(dashboard)/pricing/components/DiamondPlanCard";
import SwiperForProModal from "./SwiperForProModal";

export const ProModal = () => {
  const proModal = useProModal();
  //   const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(proModal.isOpen);
  }, [proModal.isOpen]);

  if (!isMounted) {
    return false;
  }

  return (
    <Dialog open={proModal.isOpen} onOpenChange={proModal.onClose}>
      <DialogContent className="flex flex-col max-w-none 2xl:max-w-[80rem]">
        <DialogHeader>
          <DialogTitle className="flex justify-center items-center flex-col gap-y-4 pb-2">
            <div className="flex items-center gap-x-2 font-bold text-xl">
              Top up your credits
              {/* <Badge className="uppercase text-sm py-1">pro</Badge> */}
            </div>
          </DialogTitle>
          <DialogDescription className="flex  flex-col text-center space-y-2 text-zinc-900 font-medium">
            ...Maybe upgrade your plan from our selection or buy some standalone
            credits?
          </DialogDescription>
        </DialogHeader>

        {/* <div className="z-[99999] h-max w-max"> */}
        <TooltipProvider>
          {/* <Swiper >
              <GoldPlanViewOnly />
              <PlatinumPlanViewOnly />
              <DiamondPlanViewOnly />
            </Swiper> */}
          <SwiperForProModal
            elements={[
              <GoldPlanViewOnly />,
              <PlatinumPlanViewOnly />,
              <DiamondPlanViewOnly />,
            ]}
          />
        </TooltipProvider>
        {/* </div> */}

        <DialogFooter>
          <div className="mx-auto items-center text-muted-foreground">
            <Link
              href="/pricing"
              onClick={proModal.onClose}
              className={buttonVariants({
                variant: "default",
              })}
            >
              Top up your credits to enjoy the full benefits of xchess.AI
            </Link>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
